import axios from 'axios'
import { APP_CONFIG } from '../config/app'

const instance = axios.create({
  baseURL: APP_CONFIG.SERVER_URI,
  headers: {
    'Cache-Control': 'no-cache',
    common: {
      'x-api-key': APP_CONFIG.X_API_KEY
    }
  }
})

export { instance as axios }
